<header class="header">
  <div class="logo">
    <img
      class="logo-img"
      src="assets/ui/images/svg/logo-1.svg"
      width="152"
      height="49"
      alt="Kittch"
    />
  </div>

  <div class="right-side">
    <div class="search">
      <img
        class="search-icon"
        src="assets/ui/images/svg/search.svg"
        width="22"
        height="22"
        alt="Search"
      />
    </div>

    <div class="separator"></div>

    <div class="profile-info">
      <app-avatar [url]="avatar" [size]="'header'"></app-avatar>
      <div class="user-name">{{ userName }}</div>

      <div class="burger-menu" (click)="burgerClick()" [ngClass]="burgerMenuIsOpen ? 'active' : ''">
        <span class="burger-menu__line"></span>
        <span class="burger-menu__line"></span>
        <span class="burger-menu__line"></span>
      </div>
    </div>
  </div>
</header>
