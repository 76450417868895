import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@kitch/data-access/guards';

import { PageNotFoundComponent } from '@kitch/ui/components';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/admin-panel/admin-panel.module').then(
        (m) => m.AdminPanelModule,
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/admin-login-page/admin-login.module').then(
        (m) => m.AdminLoginModule,
      ),
  },

  { path: 'not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: CustomPreloading,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  providers: [],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
