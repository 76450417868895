import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxEditorModule } from 'ngx-editor';
import { MaterialModule } from '@kitch/material';
import { UsersService } from '@kitch/admin/shared/services/users.service';
import { UiModule } from '@kitch/ui';

import { adminSharedComponents } from './components';

@NgModule({
  declarations: [...adminSharedComponents],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    UiModule,
    RouterModule,
    NgxEditorModule.forRoot({
      locals: {
        bold: 'Bold',
        italic: 'Italic',
        underline: 'Underline',
      },
    }),
  ],
  exports: [...adminSharedComponents, CommonModule, ReactiveFormsModule, MaterialModule, FormsModule],
  providers: [UsersService],
})
export class AdminSharedModule {}
