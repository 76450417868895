import { Component, OnInit } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { LoggerService, ProfilesService, TokenService } from '@kitch/data-access/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  burgerMenuIsOpen = false;
  avatar = '';
  userName = 'Administrator';

  constructor(
    private tokenService: TokenService,
    private profileService: ProfilesService,
    private logger: LoggerService,
  ) { }

  ngOnInit() {
    this.profileService.getUserProfile(this.tokenService.getProfileId())
      .pipe(
        map((profiles) => profiles.results[0]),
        filter((profile) => !!profile),
      )
      .subscribe((profile) => {
        this.avatar = profile.photo;
        this.userName = profile.displayName || this.userName;
      },
      (error) => this.logger.error('HeaderComponent getUserProfile error', error),
      );
  }

  burgerClick(): void {
    this.burgerMenuIsOpen = !this.burgerMenuIsOpen;
  }
}
