<aside class="menu">
  <ul class="">
    <li class="menu__item" routerLinkActive="active">
      <a class="menu__link" tabindex="-1" [routerLink]="['/']" routerLinkActive="router-link-active" >
        <span class="menu__text">Users</span>
      </a>
    </li>
    <li class="menu__item" routerLinkActive="active">
      <a class="menu__link" tabindex="-1" [routerLink]="['/streams']" routerLinkActive="router-link-active" >
        <span class="menu__text">Streams</span>
      </a>
    </li>
    <li class="menu__item" routerLinkActive="active">
      <a class="menu__link" tabindex="-1" [routerLink]="['/recipes']" routerLinkActive="router-link-active" >
        <span class="menu__text">Recipes</span>
      </a>
    </li>
    <li class="menu__item" routerLinkActive="active">
      <a class="menu__link" tabindex="-1" [routerLink]="['/withdraw']" routerLinkActive="router-link-active" >
        <span class="menu__text">Withdraw requests</span>
      </a>
    </li>
    <li class="menu__item" routerLinkActive="active">
      <a class="menu__link" tabindex="-1" [routerLink]="['/reports']" routerLinkActive="router-link-active" >
        <span class="menu__text">Reports</span>
      </a>
    </li>
    <li class="menu__item" routerLinkActive="active">
      <a class="menu__link" tabindex="-1" [routerLink]="['/finance']" routerLinkActive="router-link-active" >
        <span class="menu__text">Finance</span>
      </a>
    </li>
    <li class="menu__item" routerLinkActive="active">
      <a class="menu__link" tabindex="-1" routerLinkActive="router-link-active" >
        <span (click)="logout()" class="menu__text">Log Out</span>
      </a>
    </li>
  </ul>
</aside>
