import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { init } from '@sentry/angular-ivy';
import { AdminModule } from './app/admin.module';
import { environment } from './environments/environment';

if (environment.enableSentryLogs) {
  init({
    dsn: environment.sentryDns,
    environment: environment.name,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AdminModule)
  .catch((err) => console.error(err));
