import { HttpClient } from '@angular/common/http';
import { InjectionToken, NgModule, NgZone, Optional, PLATFORM_ID, SkipSelf } from '@angular/core';

import { Router } from '@angular/router';
import { AuthGuard } from '@kitch/data-access/guards';
import { httpInterceptorProviders } from '@kitch/data-access/interceptors';
import { CommonUserRole } from '@kitch/data-access/models';
import {
  ApiService,
  AuthService,
  FetchApiService,
  LoggerService,
  ProfilesService,
  TokenService,
  WebsocketService,
} from '@kitch/data-access/services';

import { AlertService, PreviousRouteService } from '@kitch/ui/services';

export const ADMIN_ROLE: InjectionToken<CommonUserRole> = new InjectionToken(
  'AdminRole',
  { factory: () => CommonUserRole.ADMIN },
);

export const LOGIN_PAGE_URL: InjectionToken<string> = new InjectionToken(
  'LoginPageUrl',
  { factory: () => 'login' },
);

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    AuthGuard,
    ApiService,
    AlertService,
    FetchApiService,
    PreviousRouteService,
    LoggerService,
    {
      provide: AuthService,
      useClass: AuthService,
      deps: [
        LOGIN_PAGE_URL,
        ADMIN_ROLE,
        ApiService,
        HttpClient,
        Router,
        TokenService,
        WebsocketService,
        NgZone,
        PLATFORM_ID,
      ],
    },
    {
      provide: TokenService,
      useClass: TokenService,
      deps: [ADMIN_ROLE],
    },
    ProfilesService,
    WebsocketService,
    ...httpInterceptorProviders,
  ],
})
export class AdminCoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: AdminCoreModule,
    private previousRouteService: PreviousRouteService,
  ) {
    if (parentModule) {
      throw new Error(
        'Core module is already loaded. Import it in the root AppModule only',
      );
    }
  }
}
