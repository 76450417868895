import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoggerModule } from 'ngx-logger';
import { SentryErrorHandler } from '@kitch/data-access/interceptors/sentry-error-handler.service';
import { AdminRoutingModule } from '@kitch/admin/admin-routing.module';
import { AppComponent } from '@kitch/admin/app.component';
import { AdminCoreModule } from '@kitch/admin/core/admin-core.module';
import { AdminSharedModule } from '@kitch/admin/shared/admin-shared.module';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AdminRoutingModule,
    AdminCoreModule,
    AdminSharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoggerModule.forRoot(environment.logging),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    // Date pipe provide for ngx-logger
    {
      provide: DatePipe,
    },
  ],
  bootstrap: [AppComponent],
})
export class AdminModule {}
